import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import NotifyCard from "./NotifyCard";
import { StatusSnackbar } from "../../components/StatusSnackbar";

import { clickNavigate, formatStatusString, getMinuteDelta } from "../../util";
import { getDirections } from "../../services/google";

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));

export const RouteInfoStep = ({ values, goBack, next }) => {
  const { origin, destination, avoidTolls } = values;
  const [routeInfo, setRouteInfo] = useState(null);
  const classes = useStyles();
  const setClippedRouteInfo = result => {
    setRouteInfo({ ...result.routes[0].legs[0], resultsObtained: new Date() });
  };

  useEffect(() => {
    getDirections(origin, destination, avoidTolls)
      .then(result => setClippedRouteInfo(result))
      .catch(result =>
        console.log("error getting directions, result: ", result)
      );
  }, []);
  return (
    <div>
      {routeInfo !== null ? (
        <div>
          {getMinuteDelta(
            routeInfo.duration.value,
            routeInfo.duration_in_traffic.value
          ) > 0 ? (
            <div>
              <StatusSnackbar
                variant="info"
                className={classes.margin}
                message={formatStatusString(routeInfo)}
              />
              <NotifyCard
                routeInfo={routeInfo}
                goBack={goBack}
                next={next}
                origin={origin}
                destination={destination}
              />
            </div>
          ) : (
            <div>
              <StatusSnackbar
                variant="success"
                className={classes.margin}
                message={`${formatStatusString(
                  routeInfo
                )} That's great, you should leave right now!`}
              />
              <Button onClick={goBack}>Back</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  clickNavigate(
                    destination.formatted_address,
                    origin.formatted_address
                  )
                }
              >
                Navigate
              </Button>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
