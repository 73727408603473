import React, { useEffect, useState } from "react";

import {
  makeStyles,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  InputLabel,
  MenuItem,
  Typography,
  FormControl,
  Select
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { AutocompleteComponent } from "../../components/AutocompleteComponent";
import { hasLocationPermission } from "../../util";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  expansionPanelDetails: {
    flexDirection: "column",
    maxWidth: "360px"
  }
}));

export const FavoriteAdd = ({ disabled, addFavorite }) => {
  const [selectType, setSelectType] = useState("route");
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [locationEnabled, setLocationEnabled] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const getLocationPermission = async () => {
      if (await hasLocationPermission()) {
        setLocationEnabled(true);
        setSelectType("place");
      } else {
        setLocationEnabled(false);
        setSelectType("route");
      }
    };
    getLocationPermission();
  }, []);

  const resetComponent = () => {
    setOrigin(null);
    setDestination(null);
    setExpanded(false);
  };

  const handleAddFavorite = () => {
    addFavorite({ origin, destination });
    resetComponent();
  };

  const handleExpanded = (event, expanded) => {
    if (!expanded) {
      resetComponent();
    } else {
      setExpanded(expanded);
    }
  };

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleExpanded}
      disabled={disabled}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          Add a Favorite{" "}
          {disabled ? (
            <span>
              {" "}
              - limit of <b>5</b> reached
            </span>
          ) : null}
        </Typography>
      </ExpansionPanelSummary>
      {expanded ? (
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <FormControl className={classes.formControl}>
            <InputLabel>Type</InputLabel>
            <Select
              value={selectType}
              onChange={event => setSelectType(event.target.value)}
            >
              <MenuItem disabled={!locationEnabled} value={"place"}>
                Place{" "}
                {locationEnabled
                  ? "(tracked from your location)"
                  : "(location is disabled)"}
              </MenuItem>
              <MenuItem value={"route"}>Route</MenuItem>
            </Select>
          </FormControl>
          {selectType === "route" ? (
            <AutocompleteComponent
              name="Starting Location"
              id="origin-autocomplete"
              disabled={disabled}
              setPlace={place =>
                setOrigin({
                  formatted_address: place.formatted_address,
                  name: place.name,
                  location: {
                    lat: place.geometry.location.lat().toString(),
                    lng: place.geometry.location.lng().toString()
                  },
                  photo_url:
                    place.photos && place.photos.length > 0
                      ? place.photos[0].getUrl()
                      : null
                })
              }
            ></AutocompleteComponent>
          ) : (
            <br />
          )}
          <AutocompleteComponent
            name={selectType === "route" ? "Destination" : ""}
            id="destination-autocomplete"
            includePhotos={true}
            disabled={disabled}
            setPlace={place =>
              setDestination({
                formatted_address: place.formatted_address,
                name: place.name,
                location: {
                  lat: place.geometry.location.lat().toString(),
                  lng: place.geometry.location.lng().toString()
                },
                photo_url:
                  place.photos && place.photos.length > 0
                    ? place.photos[0].getUrl()
                    : null
              })
            }
          ></AutocompleteComponent>
          <br />
          <Button
            size="small"
            variant="contained"
            disabled={
              destination === null ||
              (selectType === "route" && origin === null)
            }
            onClick={handleAddFavorite}
          >
            add
          </Button>
        </ExpansionPanelDetails>
      ) : null}
    </ExpansionPanel>
  );
};
