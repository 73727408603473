import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import DirectionsIcon from "@material-ui/icons/Directions";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

const useStyles = makeStyles(theme => ({
  fabAction: {
    margin: "0 8px 0 0"
  }
}));

export const FavoriteSpeedDial = ({
  handleNavigate,
  handleDelete,
  handleTrackFavorite
}) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <SpeedDial
      ariaLabel="SpeedDial example"
      className={classes.speedDial}
      icon={<SpeedDialIcon />}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      direction="left"
      FabProps={{ color: "default", size: "small" }}
    >
      <SpeedDialAction
        icon={<DirectionsIcon fontSize="small" />}
        tooltipTitle="Navigate"
        tooltipPlacement="top"
        onClick={handleNavigate}
        className={classes.fabAction}
      />
      <SpeedDialAction
        icon={<TrendingDownIcon fontSize="small" />}
        tooltipTitle="Track"
        tooltipPlacement="top"
        onClick={handleTrackFavorite}
        className={classes.fabAction}
      />
      <SpeedDialAction
        icon={<ClearIcon fontSize="small" />}
        tooltipTitle="Delete"
        tooltipPlacement="top"
        onClick={handleDelete}
        className={classes.fabAction}
      />
    </SpeedDial>
  );
};
