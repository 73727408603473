import React from "react";

import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";

import Button from "./Button";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "50px",
    backgroundColor: theme.palette.primary.main
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    color: "white"
  },
  flexRow: {
    flexDirection: "row"
  },
  flexCol: {
    flexDirection: "column"
  },
  buttonContainer: {
    padding: "50px",
    textAlign: "center"
  },
  textContainer: {
    textAlign: "center"
  },
  textCell: {
    margin: "10px"
  }
}));
export const CallToAction = ({ history }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:720px)");

  return (
    <div className={classes.root}>
      <Container
        maxWidth="lg"
        className={clsx(
          classes.container,
          matches ? classes.flexRow : classes.flexCol
        )}
      >
        <div className={classes.textContainer}>
          <Typography className={classes.textCell} variant="h4">
            Use without logging in
          </Typography>
          <Typography className={classes.textCell} variant="subtitle1">
            No login or account creation require to start tracking routes!
          </Typography>
        </div>
        <div className={classes.buttonContainer}>
          <Button big />
        </div>
      </Container>
    </div>
  );
};
