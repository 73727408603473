import * as moment from "moment";
import isMobile from "ismobilejs";

import tracking from "../services/tracking";

import { CURRENT_LOCATION_STRING } from "../strings";

export const formatPercentage = percentage =>
  parseFloat(Math.round(percentage * 100) / 100).toFixed(2) + "%";

export const calculateTrafficDelta = (duration, durationInTraffic) =>
  ((durationInTraffic - duration) / duration) * 100;

export const formatMinutes = (minutes, short = false) => {
  const hours = Math.floor(minutes / 60);
  minutes = Math.round(minutes % 60);
  const mText = short ? "m" : "min";
  const between = short ? "" : " ";
  return `${(hours > 0 ? hours + between + "h" : "") +
    (minutes > 0 ? (hours > 0 ? " " : "") + minutes + between + mText : "")}`;
};

export const formatSeconds = (seconds, short = false) =>
  formatMinutes(getMinutesFromSeconds(seconds), short);

export const getMinutesFromSeconds = seconds => Math.round(seconds / 60);

export const calculateMinDifference = (secondsA, secondsB) =>
  getMinutesFromSeconds(secondsB) - getMinutesFromSeconds(secondsA);

export const formatTimestamp = (timestamp, timeOnly) => {
  return formatDate(timestamp.toDate(), timeOnly);
};

export const formatDate = (date, timeOnly) => {
  let m = moment(date);
  if (m > moment().startOf("day") || timeOnly) {
    // today
    return m.format("h:mm a");
  } else if (
    m >
    moment()
      .startOf("day")
      .subtract(1, "days")
  ) {
    return m.format("[yesterday,] h:mm a");
  } else {
    return m.format("ddd, MMM Do, h:mm a");
  }
};

export const getMinuteDelta = (duration, durationInTraffic) => {
  return Math.round((durationInTraffic - duration) / 60);
};

export const formatStatusString = routeInfo => {
  // account for routeInfo from google or from storage (why did I change format!?)
  const durationInTraffic = routeInfo.duration_in_traffic
    ? routeInfo.duration_in_traffic.value
    : routeInfo.durationInTraffic;
  const duration = routeInfo.duration.value
    ? routeInfo.duration.value
    : routeInfo.duration;
  return `${formatSeconds(durationInTraffic)} (${
    getMinuteDelta(duration, durationInTraffic) > 0
      ? `${formatMinutes(
          getMinuteDelta(duration, durationInTraffic)
        )}  of traffic)`
      : "no traffic)"
  }`;
};

export const clickNavigate = (
  destination_formatted_address,
  origin_formatted_address = null
) => {
  tracking.logEvent("click_navigate", {
    origin_formatted_address,
    destination_formatted_address
  });
  window.open(
    `https://www.google.com/maps/dir/?api=1${
      origin_formatted_address !== null
        ? `&origin=${origin_formatted_address}`
        : ""
    }&destination=${destination_formatted_address}&travelmode=driving`
  );
};

const getPosition = options => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

export const hasLocationPermission = async () => {
  try {
    await getPosition();
    return true;
  } catch (error) {
    console.log("error getting user location: ", error);
    return false;
  }
};

export const getCurrentLocation = async () => {
  try {
    const geocoder = new window["google"].maps.Geocoder();
    const position = await getPosition();
    return new Promise((resolve, reject) => {
      const location = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      geocoder.geocode({ location }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            resolve({
              formatted_address: results[0].formatted_address,
              name: CURRENT_LOCATION_STRING,
              location: {
                lat: location.lat.toString(),
                lng: location.lng.toString()
              }
            });
          } else {
            reject("No results found");
          }
        } else {
          reject("Geocoder failed due to: " + status);
        }
      });
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const isMobileDevice = () => {
  return isMobile().phone;
};
