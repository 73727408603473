import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import WarningIcon from "@material-ui/icons/Warning";

import red from "@material-ui/core/colors/red";

import firebase from "../../util/firebase";
import tracking from "../../services/tracking";

import { getMinutesFromSeconds } from "../../util";
import { ThresholdSlider } from "../../components/ThresholdSlider";

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    flexDirection: "column",
    "max-width": "600px"
  },
  form: {
    padding: "10px"
  },
  fit: {
    "max-width": "fit-content"
  },
  warningContainer: {
    padding: "10px",
    display: "flex",
    "justify-content": "flex-start",
    "align-items": "center"
  },
  warning: {
    color: red[400],
    display: "flex",
    "justify-content": "flex-start",
    "align-items": "center",
    "flex-shrink": 1
  },
  warningIcon: {
    padding: "2px"
  }
}));

const NotifyCard = ({ routeInfo, next, goBack, origin, destination }) => {
  const [threshold, setThreshold] = useState(null);
  const [expiryHours, setExpiryHours] = useState(1);
  const [isCurrentTask, setIsCurrentTask] = useState(false);
  const [useThreshold, setUseThreshold] = useState(false);

  const { duration, duration_in_traffic } = routeInfo;
  const history = useHistory();

  useEffect(() => {
    if (useThreshold) {
      setThreshold(getMinutesFromSeconds(duration.value));
    } else {
      setThreshold(null);
    }
  }, [useThreshold]);

  useEffect(() => {
    const getCurrentTask = async () => {
      let data = (await firebase.userDoc().get()).data();
      setIsCurrentTask(data && !!data.currentTask);
    };
    getCurrentTask();
  }, []);

  const classes = useStyles();

  const submitNotifyTask = async () => {
    const task = await firebase.submitNotifyTask(
      origin,
      destination,
      threshold,
      routeInfo,
      expiryHours
    );
    tracking.logEvent("submit_notify_task", task);
    history.push("/status");
  };
  return (
    <div>
      <div className={classes.content}>
        <FormGroup className={classes.form}>
          <FormControl className={classes.fit}>
            <InputLabel>track for</InputLabel>
            <Select
              value={expiryHours}
              onChange={event => setExpiryHours(event.target.value)}
            >
              <MenuItem value={1}>1 hour</MenuItem>
              <MenuItem value={2}>2 hours</MenuItem>
              <MenuItem value={3}>3 hours</MenuItem>
            </Select>
          </FormControl>
          {firebase.isMessagingSupported() ? (
            <FormControlLabel
              control={
                <Switch
                  checked={useThreshold}
                  onChange={event => setUseThreshold(event.target.checked)}
                  color="primary"
                  value="useThreshold"
                />
              }
              label="Set travel time notification"
            />
          ) : null}
        </FormGroup>
        {useThreshold && threshold !== null ? (
          <ThresholdSlider
            threshold={threshold}
            onChange={setThreshold}
            min={getMinutesFromSeconds(duration.value)}
            max={getMinutesFromSeconds(duration_in_traffic.value)}
          />
        ) : null}
      </div>

      <div>
        <Button onClick={goBack}>Back</Button>
        <Button onClick={submitNotifyTask} variant="contained" color="primary">
          Start Tracking
        </Button>
        {isCurrentTask ? <WarningMessage /> : null}
      </div>
    </div>
  );
};

const WarningMessage = () => {
  const classes = useStyles();
  return (
    <div className={classes.warningContainer}>
      <Tooltip title="You can only track one route at a time." placement="top">
        <div className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          This will overwrite your current route!
        </div>
      </Tooltip>
    </div>
  );
};

export default NotifyCard;
