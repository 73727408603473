import React from "react";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import clsx from "clsx";

import firebase from "../../services/firebase";

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#DDDDDD",
      color: theme.palette.primary.dark
    }
  },
  big: {
    padding: "20px 50px"
  }
}));
export default function GetStartedButton({ big }) {
  const classes = useStyles();
  const history = useHistory();
  const handleClick = async () => {
    if (!firebase.auth().currentUser) await firebase.auth().signInAnonymously();
    history.push("/status");
  };
  return (
    <Button
      size="large"
      className={clsx(classes.button, big ? classes.big : null)}
      onClick={handleClick}
    >
      Get Started
    </Button>
  );
}
