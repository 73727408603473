import React from "react";

import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import logo from "../../assets/logo-transparent.svg";

const useStyles = makeStyles(theme => ({
  root: { margin: "100px 0 50px" },
  container: {
    display: "flex",
    flexDirection: "row"
  },
  flexCol: {
    flexDirection: "column"
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "16px"
  },
  logo: {
    maxWidth: "200px",
    marginBottom: "50px"
  },
  full: {
    width: "96%"
  },
  half: {
    width: "42%"
  },
  quarter: {
    width: "21%"
  },
  listHeader: {
    fontWeight: "bold",
    color: "black"
  },
  copyright: {
    marginTop: "90px",
    textAlign: "center"
  }
}));
export default function Footer() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:720px)");
  const handleClick = url => window.open(url, "_new");

  return (
    <div className={classes.root}>
      <Container
        className={clsx(classes.container, matches ? null : classes.flexCol)}
        maxWidth="lg"
      >
        <div
          className={clsx(
            classes.logoContainer,
            matches ? classes.half : classes.full
          )}
        >
          <img className={classes.logo} src={logo} />
          <Typography variant="caption">
            A software that was created to help people avoid traffic.
          </Typography>
        </div>
        <List
          className={clsx(matches ? classes.quarter : classes.full)}
          subheader={
            <ListSubheader className={classes.listHeader}>
              Roadgauge
            </ListSubheader>
          }
        >
          <ListItem
            button
            onClick={() => handleClick("https://gabeoleary.com")}
          >
            Created by Gabe O'Leary
          </ListItem>
          <ListItem
            button
            onClick={() =>
              handleClick(
                "https://docs.google.com/forms/d/e/1FAIpQLSc9eU_oL19dgmnfMcQXov7aDBx3cvYef8xHX4btfxWLhZFU5Q/viewform?usp=sf_link"
              )
            }
          >
            Feedback
          </ListItem>
        </List>
        <List
          className={clsx(matches ? classes.quarter : classes.full)}
          subheader={
            <ListSubheader className={classes.listHeader}>
              Support
            </ListSubheader>
          }
        >
          <ListItem
            button
            onClick={() => handleClick("mailto:oleary.gabe@gmail.com")}
          >
            Contact
          </ListItem>
          <ListItem
            button
            onClick={() => handleClick("mailto:oleary.gabe@gmail.com")}
          >
            Privacy
          </ListItem>
          <ListItem
            button
            onClick={() => handleClick("mailto:oleary.gabe@gmail.com")}
          >
            Terms of Use
          </ListItem>
        </List>
      </Container>
      <div className={classes.copyright}>
        <Typography variant="body2">
          Copyright {new Date().getFullYear()}, {` `}{" "}
          <a href="https://roadgauge.app" title={"Roadgauge"}>
            Roadgauge
          </a>
          . All rights reserved.
        </Typography>
      </div>
    </div>
  );
}
