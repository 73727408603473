import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";

import AddIcon from "@material-ui/icons/Add";
import DirectionsIcon from "@material-ui/icons/Directions";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import RefreshIcon from "@material-ui/icons/Refresh";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import { withLoadingScreen } from "../../hoc";

import firebase from "../../util/firebase";
import tracking from "../../services/tracking";

import {
  getMinutesFromSeconds,
  formatTimestamp,
  clickNavigate,
  formatStatusString
} from "../../util";
import { NewUserEducation } from "./NewUserEducation";
import { TimeChart } from "../../components/TimeChart";

import { StatusSnackbar } from "../../components/StatusSnackbar";
import { RouteHeader } from "../../components/RouterHeader";
import { ThresholdSlider } from "../../components/ThresholdSlider";
import { LoadingComponent } from "../../components/LoadingComponent";

const useStyles = makeStyles(theme => {
  return {
    root: {
      padding: theme.spacing(1, 1),
      flexGrow: 1,
      maxWidth: "800px"
    },
    actions: {
      "border-top": "1px solid #dadce0",
      display: "flex"
    },
    actionButon: {
      marginLeft: "auto"
    },
    buttonIcon: {
      marginRight: theme.spacing(1)
    },
    bigAvatar: {
      margin: 10,
      width: 60,
      height: 60
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    notificationButton: {
      padding: "5px",
      marginLeft: "10px",
      border: `1px solid ${
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)"
      }`,
      "&$disabled": {
        border: `1px solid ${theme.palette.action.disabled}`
      },
      "&.active": {
        backgroundColor: "#7986cb5c"
      }
    },
    thresholdContainer: {
      padding: "0 10px 0"
    }
  };
});

const StatusPage = ({ user, profile, currentTask, displayHere }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(0);
  const [status, setStatus] = useState(currentTask);

  const collection = `users/${user.uid}/archivedTasks`;
  useFirestoreConnect({
    collection,
    limit: 0
  });
  const archivedTask = useSelector(
    state =>
      state.firestore.ordered[collection] &&
      state.firestore.ordered[collection][0]
  );
  useEffect(() => {
    if (!currentTask) setLimit(1);
    else setLimit(0);
  }, [currentTask]);

  useEffect(() => {
    if (currentTask !== null) setStatus(currentTask);
    else setStatus(archivedTask);
  }, [currentTask, archivedTask]);

  const getRouteTimeText = () => (
    <span>
      <b>{formatStatusString(status.apiResults[0])}</b>
      {" - "}
      {formatTimestamp(status.apiResults[0].resultsObtained)}
    </span>
  );

  const isActive = () => {
    return !isExpired() && !status.completed;
  };

  const isExpired = () => {
    return status.expiryTime < Date.now();
  };
  const expiresWithin2Hours = () => {
    return status.expiryTime < Date.now() + 1000 * 60 * 60 * 2;
  };
  const getStatusSnackbar = () => {
    if (status.completed) {
      // threshold met
      return (
        <StatusSnackbar
          variant="success"
          message={`Threshold met at ${formatTimestamp(
            status.apiResults[0].resultsObtained
          )}, time to leave!`}
        />
      );
    } else {
      return (
        <StatusSnackbar
          variant="info"
          message={
            `Tracking expired for this route` /* at ${formatTimestamp(
            status.expiry
          )}`*/
          }
          includeRefreshButton
        />
      );
    }
  };

  const handleClickRefresh = () => {
    firebase.refreshLatestRoute();
    tracking.logEvent("refresh_route");
  };

  const handleClickNotify = () => {
    if (status.thresholdInMinutes !== null) {
      firebase.updateThreshhold(null);
    } else {
      firebase.updateThreshhold(
        getMinutesFromSeconds(status.apiResults[0].duration)
      );
    }
  };
  const handleClickAddHour = () => {
    firebase.addHour();
    tracking.logEvent("add_hour");
  };
  if (!status && isEmpty(profile)) return <NewUserEducation />;
  else if (status)
    return (
      <div className={classes.root}>
        <RouteHeader status={status} />
        <Typography className={classes.heading}></Typography>
        {!isActive() ? getStatusSnackbar() : null}
        {status.apiResults && status.apiResults.length > 0 ? (
          <Card>
            <CardHeader
              title="Drive time"
              subheader={getRouteTimeText()}
              action={
                !isActive() ? (
                  <IconButton onClick={handleClickRefresh} aria-label="refresh">
                    <RefreshIcon />
                  </IconButton>
                ) : null
              }
            />
            <CardContent>
              <TimeChart
                apiResults={status.apiResults}
                threshold={status.thresholdInMinutes}
                displayHere={displayHere}
              />
            </CardContent>
            <CardActions className={classes.actions}>
              {isActive() && firebase.isMessagingSupported() ? (
                <IconButton
                  onClick={handleClickNotify}
                  className={clsx([
                    classes.notificationButton,
                    status.thresholdInMinutes !== null ? "active" : ""
                  ])}
                  color="primary"
                >
                  {status.thresholdInMinutes !== null ? (
                    <NotificationsIcon fontSize="small" />
                  ) : (
                    <NotificationsNoneIcon fontSize="small" />
                  )}
                </IconButton>
              ) : null}
              {isActive() && expiresWithin2Hours() ? (
                <Button
                  className={classes.actionButon}
                  onClick={handleClickAddHour}
                >
                  <AddIcon className={classes.buttonIcon} />
                  Add 1 hour
                </Button>
              ) : null}
              <Button
                className={classes.actionButon}
                onClick={() =>
                  clickNavigate(
                    status.destination.formatted_address,
                    status.origin.formatted_address
                  )
                }
              >
                <DirectionsIcon className={classes.buttonIcon} />
                Navigate
              </Button>
            </CardActions>
            <div className={classes.thresholdContainer}>
              {isActive() && status.thresholdInMinutes !== null ? (
                <ThresholdSlider
                  threshold={status.thresholdInMinutes}
                  onChangeCommitted={threshold =>
                    firebase.updateThreshhold(threshold)
                  }
                  min={getMinutesFromSeconds(status.apiResults[0].duration)}
                  max={getMinutesFromSeconds(
                    status.apiResults[0].durationInTraffic
                  )}
                />
              ) : null}
            </div>
          </Card>
        ) : null}
      </div>
    );
  else return <LoadingComponent />;
};

const mapStateToProps = state => ({
  user: state.firebase.auth,
  profile: state.firebase.profile,
  currentTask: state.firebase.profile.currentTask,
  displayHere:
    state.firebase.profile &&
    state.firebase.profile.settings &&
    state.firebase.profile.settings.displayHere
});

export default withLoadingScreen(connect(mapStateToProps)(StatusPage));
