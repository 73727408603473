import React from "react";

import { amber, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import RefreshIcon from "@material-ui/icons/Refresh";

import firebase from "../util/firebase";
import tracking from "../services/tracking";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "center"
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

export const StatusSnackbar = props => {
  const classes = useStyles1();

  const handleClickRefresh = () => {
    firebase.refreshLatestRoute();
    tracking.logEvent("refresh_route");
  };

  const RefreshButton = (
    <IconButton color="inherit" size="small" onClick={handleClickRefresh}>
      <RefreshIcon />
    </IconButton>
  );
  const { className, message, variant, includeRefreshButton, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <div className={classes.margin}>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={includeRefreshButton ? RefreshButton : null}
        {...other}
      />
    </div>
  );
};
