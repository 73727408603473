import React from "react";
import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

import { BrowserRouter as Router } from "react-router-dom";

import * as Sentry from "@sentry/browser";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import store from "./redux/store";

import firebase from "./services/firebase";

Sentry.init({
  dsn: "https://3e9d1568a2d6474eb11dec6ce68fd0e4@sentry.io/2048544"
});

const rrfProps = {
  firebase,
  config: {
    userProfile: "users",
    useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  },
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
};

const rootElement = document.getElementById("root");

const reactApp = (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <Router>
        <App />
      </Router>
    </ReactReduxFirebaseProvider>
  </Provider>
);

if (rootElement.hasChildNodes()) {
  hydrate(reactApp, rootElement);
} else {
  render(reactApp, rootElement);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
