import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import {
  CssBaseline,
  Container,
  createMuiTheme,
  makeStyles
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import firebase from "./util/firebase";

import StatusPage from "./pages/StatusPage";
import AddRoutePage from "./pages/AddRoutePage";
import FavoritesPage from "./pages/FavoritesPage";
import SettingsPage from "./pages/SettingsPage";
import LandingPage from "./pages/Landing";

import { NotificationErrorPage } from "./components/NotificationErrorPage";
import { SimpleSnackbar } from "./components/NotificationSnackBar";
import { BottomNavComponent } from "./components/BottomNavComponent";

import "./App.css";

const messaging = firebase.messaging;

const useStyles = makeStyles(theme => ({
  root: {
    padding: "10px 10px 66px",
    display: "flex",
    justifyContent: "center"
  }
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4960a6"
    },
    secondary: {
      main: "#ff8a65"
    }
  }
});

const App = ({ profile }) => {
  const [messagePayload, setMessagePayload] = useState(null);
  console.log("document.cookie:", document.cookie);
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  useEffect(
    () =>
      firebase.onAuthStateChanged(user => {
        user && location.pathname === "/" && history.push("/status");
      }),
    []
  );
  useEffect(() => {
    let unsubscribe = null;
    const setupForegroundNotifications = () => {
      unsubscribe = messaging.onMessage(messagePayload => {
        console.log("onMessage: ", messagePayload);
        setMessagePayload(messagePayload);
        const { title, body } = messagePayload.notification;
        let n = (new Notification(title, {
          body
        }).onclick = event => {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          window.open(messagePayload.fcmOptions.link, "_blank");
        });
      });
    };
    if (isLoaded(profile) && firebase.isMessagingSupported())
      setupForegroundNotifications();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [profile]);

  return (
    <ThemeProvider theme={theme}>
      <Container className={classes.root}>
        <CssBaseline>
          <SimpleSnackbar messagePayload={messagePayload} />
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route
              exact
              path="/status"
              render={() => <StatusPage loading={!isLoaded(profile)} />}
            />
            <Route
              exact
              path="/add-route"
              render={() => <AddRoutePage loading={!isLoaded(profile)} />}
            />
            <Route
              path="/favorites"
              render={() => <FavoritesPage loading={!isLoaded(profile)} />}
            />
            <Route
              path="/settings"
              render={() => <SettingsPage loading={!isLoaded(profile)} />}
            />
            <Route
              path="/notification-error"
              component={NotificationErrorPage}
            />
            <Route path="/landing-page" component={LandingPage} />
          </Switch>
          {location.pathname !== "/" ? <BottomNavComponent /> : null}
        </CssBaseline>
      </Container>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  profile: state.firebase.profile
});

export default connect(mapStateToProps)(App);
