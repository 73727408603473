import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
}));

export const NotificationErrorPage = () => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.root}>
        <Typography variant="h4" component="h4">
          <ReportProblemIcon />
        </Typography>
        <Typography variant="h5" component="h5">
          Issue with Notifications
        </Typography>
        <Typography component="p">
          You must enable notifications to take advantage of this application.
        </Typography>
        <Typography component="p">
          Learn more{" "}
          <a
            href="https://support.google.com/chrome/answer/114662"
            target="_blank"
          >
            here
          </a>
        </Typography>
      </Paper>
    </div>
  );
};
