import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import { formatTimestamp } from "../util";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 1),
    display: "flex",
    alignItems: "center"
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60
  }
}));

export const RouteHeader = ({ status }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {status.destination.photo_url && status.destination.photo_url !== "" ? (
        <Avatar
          src={status.destination.photo_url}
          className={classes.bigAvatar}
        />
      ) : null}
      <div>
        <Typography variant="h4">
          {status.destination.name
            ? status.destination.name
            : status.destination.formatted_address}
        </Typography>
        <Typography variant="caption">
          {`from ${
            status.origin.name
              ? status.origin.name
              : status.origin.formatted_address
          } (expire${
            status.expiryTime < Date.now() ? "d" : "s"
          } ${formatTimestamp(status.expiry)})`}
        </Typography>
      </div>
    </div>
  );
};
