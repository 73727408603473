import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";

//import api from "./api";

const firebaseConfig = {
  apiKey: "AIzaSyCaAUrhso0UTRvJN2MKCrH_jC7_m7gNBJQ",
  authDomain: "traffic-notifier-1478135535462.firebaseapp.com",
  databaseURL: "https://traffic-notifier-1478135535462.firebaseio.com",
  projectId: "traffic-notifier-1478135535462",
  storageBucket: "traffic-notifier-1478135535462.appspot.com",
  messagingSenderId: "120006016590",
  appId: "1:120006016590:web:49afd87bd1a61a82",
  measurementId: "G-DJTCFVB14L"
};

firebase.initializeApp(firebaseConfig);

firebase.auth();
firebase.firestore();
firebase.analytics();

/*
firebase.auth().onAuthStateChanged(user => {
  if (!user) window.location = "/landing-page";
});
*/
/*
if (api.isDev())
  firebase.firestore().settings({ host: api.getFirebaseLocalHost() });
*/

export default firebase;
