import firebase from "../util/firebase";

const gtag = window.gtag;

class Tracking {
  logEvent(eventName, eventParams, options) {
    firebase.logEvent(eventName, eventParams, options);
    gtag("event", eventName, eventParams);
  }
}

export default new Tracking();
