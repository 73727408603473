import React from "react";

import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import DirectionsIcon from "@material-ui/icons/Directions";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";

export const FavoriteActions = ({
  handleNavigate,
  handleDelete,
  handleTrackFavorite
}) => {
  return (
    <div>
      <IconButton onClick={handleNavigate}>
        <DirectionsIcon />
      </IconButton>
      <IconButton onClick={handleTrackFavorite}>
        <TrendingDownIcon />
      </IconButton>
      <IconButton onClick={handleDelete}>
        <ClearIcon />
      </IconButton>
    </div>
  );
};
