import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isLoaded } from "react-redux-firebase";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import FeedbackIcon from "@material-ui/icons/Feedback";
import FacebookIcon from "@material-ui/icons/Facebook";
import InfoIcon from "@material-ui/icons/Info";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { LoadingComponent } from "../components/LoadingComponent";

import firebase from "../util/firebase";
import tracking from "../services/tracking";

import { withLoadingScreen } from "../hoc";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  }
}));

const SettingsPage = ({ settings }) => {
  const classes = useStyles();
  const [isAnonymous, setIsAnoynmous] = useState(firebase.isUserAnonymous());

  useEffect(() => {
    const unsubscribe = firebase.onAuthStateChanged(user => {
      setIsAnoynmous(user.isAnonymous);
    });
    return unsubscribe;
  }, []);

  const handleChange = name => event => {
    tracking.logEvent("change_setting", { name, value: event.target.checked });
    firebase.updateSettings({ ...settings, [name]: event.target.checked });
  };

  const handleLoginFacebook = () => {
    tracking.logEvent("login_with_facebook");
    firebase.linkWithFacebook();
  };

  const handleLogout = () => {
    tracking.logEvent("logout");
    firebase.logout();
  };

  const submitFeeback = () => {
    tracking.logEvent("submit_feedback");
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSc9eU_oL19dgmnfMcQXov7aDBx3cvYef8xHX4btfxWLhZFU5Q/viewform?usp=sf_link"
    );
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h4">Settings</Typography>
      <List>
        {firebase.isMessagingSupported() && null ? (
          <ListItem>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={settings && settings.pushNotificationsEnabled}
                  onChange={handleChange("pushNotificationsEnabled")}
                  value="pushNotificationsEnabled"
                />
              }
              label="Push Notifications"
            />
          </ListItem>
        ) : null}
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={settings && settings.displayHere}
                onChange={handleChange("displayHere")}
                value="displayHere"
              />
            }
            label="Display HERE Maps data (beta)"
          />
        </ListItem>
        {isAnonymous ? (
          <ListItem button onClick={handleLoginFacebook}>
            <ListItemIcon>
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText primary="Login with Facebook" />
          </ListItem>
        ) : (
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <FacebookIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        )}
        <ListItem button onClick={submitFeeback}>
          <ListItemIcon>
            <FeedbackIcon />
          </ListItemIcon>
          <ListItemText primary="Submit Feedback" />
        </ListItem>
        <ListItem button to="/landing-page" component={Link}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Landing Page" />
        </ListItem>
      </List>
      <Typography variant="body1">
        This was created by{" "}
        <a href="https://gabeoleary.com/" target="_new">
          Gabe O'Leary
        </a>
      </Typography>
      <Typography variant="body1">
        icon by{" "}
        <a href="https://thenounproject.com/olivierguin/" target="_new">
          Olivier Guin
        </a>{" "}
        from the Noun Project
      </Typography>
    </Paper>
  );
};

const mapStateToProps = state => ({
  settings: state.firebase.profile.settings
});

export default withLoadingScreen(connect(mapStateToProps)(SettingsPage));
