import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { List, ListItem } from "@material-ui/core";

import firebase from "../util/firebase";
import tracking from "../services/tracking";
import { formatDate, getCurrentLocation } from "../util";

import { withLoadingScreen } from "../hoc";

import { FavoriteListItem } from "./favorites/FavoriteListItem";
import { FavoriteAdd } from "./favorites/FavoriteAdd";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  }
}));

const FavoritesPage = ({ favorites }) => {
  const classes = useStyles();
  const [updateTime, setUpdateTime] = useState(null);
  const [currentLocation, setCurrentLocation] = useState();

  useEffect(() => {
    getCurrentLocation().then(result => setCurrentLocation(result));
  }, []);

  useEffect(() => {
    setUpdateTime(new Date());
  }, []);

  const addFavorite = async favorite => {
    if (favorite.destination !== null) {
      await firebase.addFavorite(favorite);
      tracking.logEvent("add_favorite", favorite.destination);
    }
  };
  return (
    <Paper className={classes.root}>
      <Typography variant="h4">Favorites</Typography>
      <br />
      <FavoriteAdd
        disabled={favorites && favorites.length >= 5}
        addFavorite={addFavorite}
      />
      {favorites && favorites.length > 0 ? (
        <div>
          <List dense={true}>
            <ListItem></ListItem>
            {favorites.map((favorite, i) => (
              <FavoriteListItem
                key={i}
                index={i}
                favorite={favorite}
                currentLocation={currentLocation}
              ></FavoriteListItem>
            ))}
          </List>
          {updateTime ? (
            <Typography variant="caption">
              times updated at {formatDate(updateTime)}
            </Typography>
          ) : null}
        </div>
      ) : (
        <div>
          <br />
          <Typography variant="body1">
            You can add up to <b>5</b> <b>places</b> or <b>routes</b> to easily
            see travel times & quickly start tracking traffic conditions.
          </Typography>
        </div>
      )}
    </Paper>
  );
};

const mapStateToProps = state => ({
  favorites: state.firebase.profile.favorites
});

export default withLoadingScreen(connect(mapStateToProps)(FavoritesPage));
