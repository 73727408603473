import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";

import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  row: {
    padding: "30px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  flexRow: {
    flexDirection: "row"
  },
  flexRowReverse: {
    flexDirection: "row-reverse"
  },
  flexCol: {
    flexDirection: "column"
  },

  cell: {
    maxWidth: "500px",
    textAlign: "center",
    margin: "auto",
    display: "flex",
    flexDirection: "column"
  },
  textCell: {
    margin: "10px"
  },
  imgCell: {
    padding: "10px",
    width: "50%"
  },
  img: {
    maxWidth: "95%"
  },
  paper: {}
}));

export const Feature = ({ name, description, imgUrl, reverse }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:720px)");
  console.log("matches: ", matches);
  return (
    <div
      className={clsx(
        classes.row,
        matches
          ? reverse
            ? classes.flexRowReverse
            : classes.flexRow
          : classes.flexCol
      )}
    >
      <div className={classes.cell}>
        <Typography className={classes.textCell} variant="h4">
          {name}
        </Typography>
        <Typography className={classes.textCell} variant="subtitle1">
          {description}
        </Typography>
      </div>
      <div className={classes.cell}>
        <img className={classes.img} src={imgUrl} />
      </div>
    </div>
  );
};
