import React, { useState } from "react";

import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

import { formatMinutes } from "../util";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  sliderRow: {
    display: "flex",
    "justify-content": "center",
    padding: "5px"
  },
  sliderContainer: {
    width: "80%"
  },
  thresholdText: {
    fontWeight: "bold"
  },
  valueLabel: {
    left: "calc(-50% + 5px)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000"
    },
    "white-space": "nowrap"
  }
}));

const fixMinMax = (min, max) => {
  if (min > max) {
    return [max, min];
  } else {
    return [min, max];
  }
};

export const ThresholdSlider = ({
  threshold,
  onChange,
  onChangeCommitted,
  min,
  max,
  valueLabelDisplay
}) => {
  const [sliderValue, setSliderValue] = useState(threshold);
  const classes = useStyles();
  [min, max] = fixMinMax(min, max);
  const marks = [
    {
      value: min,
      label: formatMinutes(min)
    },
    {
      value: max,
      label: formatMinutes(max)
    }
  ];

  const calculateStep = () => {
    return 1;
  };

  return (
    <div>
      <Typography variant="body1">
        We will notify you when the travel time drops below
        <span className={classes.thresholdText}>
          {" "}
          {formatMinutes(sliderValue)}
        </span>
        {"."}
      </Typography>
      <div className={classes.sliderRow}>
        <div className={classes.sliderContainer}>
          <Slider
            classes={{
              valueLabel: classes.valueLabel
            }}
            defaultValue={sliderValue}
            aria-labelledby="discrete-slider"
            step={calculateStep()}
            valueLabelDisplay={valueLabelDisplay ? valueLabelDisplay : "off"}
            valueLabelFormat={formatMinutes}
            marks={marks}
            min={min}
            max={max}
            onChange={(event, value) => {
              setSliderValue(value);
              if (onChange) onChange(value);
            }}
            onChangeCommitted={(event, value) => {
              if (onChangeCommitted) onChangeCommitted(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
