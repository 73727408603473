import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import Switch from "@material-ui/core/Switch";

import { withLoadingScreen } from "../../hoc";

import tracking from "../../services/tracking";

import { AutocompleteComponent } from "../../components/AutocompleteComponent";
import { RouteInfoStep } from "./RouteInfoStep";
import LocationAlert from "./LocationAlert";

import { getCurrentLocation, isMobileDevice } from "../../util";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "800px",
    flexGrow: 1
  },
  button: {
    height: "50px"
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  content: {
    "max-width": "450px"
  },
  center: {
    "justify-content": "center"
  }
}));

// this component is for obtaining information about a particular route from the user
// i.e. what's the origin, what's the destination? (avoid tolls etc.)

const initialValues = {
  origin: {
    formatted_address: "",
    name: "",
    location: {
      lat: "",
      lng: ""
    }
  },
  destination: {
    formatted_address: "",
    name: "",
    photo_url: "",
    location: {
      lat: "",
      lng: ""
    }
  },
  avoidTolls: false
};

const AddRoutePage = props => {
  const classes = useStyles();
  const [values, setValues] = useState(initialValues);
  const [showLocationButton, setShowLocationButton] = useState(
    navigator.geolocation && isMobileDevice()
  );
  const [locationButtonActive, setLocationButtonActive] = useState(
    isMobileDevice()
  );
  const [activeStep, setActiveStep] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function reset() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setValues(initialValues);
    setLocationButtonActive(false);
  }

  useEffect(() => {
    if (isMobileDevice()) {
      handleUseCurrentLocation();
    }
  }, []);

  const handleChange = place => {
    setValues(oldValues => ({
      ...oldValues,
      ...place
    }));
  };

  const toggleUseCurrentLocation = () => {
    tracking.logEvent("click_use_current_location");
    if (locationButtonActive === false) {
      handleUseCurrentLocation();
    } else {
      handleChange({
        origin: initialValues.origin
      });
      setLocationButtonActive(false);
    }
  };

  const handleUseCurrentLocation = async () => {
    try {
      const origin = await getCurrentLocation();
      handleChange({ origin });
      setLocationButtonActive(true);
    } catch (error) {
      console.log("error: ", error);
      if (error.message === "User denied Geolocation") {
        setAlertOpen(true);
        setLocationButtonActive(false);
        setShowLocationButton(false);
      } else {
        setShowLocationButton(false);
      }
    }
  };

  return (
    <Paper className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel>Select a route</StepLabel>
          <StepContent className={classes.content}>
            <AutocompleteComponent
              name="Starting Location"
              id="origin-autocomplete"
              setPlace={place =>
                handleChange({
                  origin: {
                    formatted_address: place.formatted_address,
                    name: place.name,
                    location: {
                      lat: place.geometry.location.lat().toString(),
                      lng: place.geometry.location.lng().toString()
                    }
                  }
                })
              }
              disabled={showLocationButton && locationButtonActive}
            >
              {showLocationButton ? (
                <Button
                  className={classes.button}
                  variant="contained"
                  color={locationButtonActive ? "primary" : "default"}
                  onClick={toggleUseCurrentLocation}
                >
                  Current
                </Button>
              ) : null}
            </AutocompleteComponent>

            <AutocompleteComponent
              name="Destination"
              id="destination-autocomplete"
              includePhotos={true}
              setPlace={place => {
                handleChange({
                  destination: {
                    formatted_address: place.formatted_address,
                    name: place.name,
                    location: {
                      lat: place.geometry.location.lat().toString(),
                      lng: place.geometry.location.lng().toString()
                    },
                    photo_url:
                      place.photos && place.photos.length > 0
                        ? place.photos[0].getUrl()
                        : null
                  }
                });
              }}
            />
            <FormGroup row className={classes.center}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.avoidTolls}
                    onChange={event =>
                      handleChange({ avoidTolls: event.target.checked })
                    }
                    value="avoidTolls"
                  />
                }
                label="Avoid Tolls"
              />
            </FormGroup>
            <Button disabled>Back</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setActiveStep(1)}
              disabled={
                values.origin.formatted_address === "" ||
                values.destination.formatted_address === ""
              }
            >
              Next
            </Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel>Current route status </StepLabel>
          <StepContent>
            <RouteInfoStep values={values} goBack={reset} next={handleNext} />
          </StepContent>
        </Step>
      </Stepper>
      <LocationAlert open={alertOpen} />
    </Paper>
  );
};

export default withLoadingScreen(AddRoutePage);
