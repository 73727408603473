import React from "react";

import { LoadingComponent } from "../components/LoadingComponent";

export const withLoadingScreen = WrappedComponent => {
  return ({ loading, ...props }) => {
    if (loading) return <LoadingComponent />;
    return <WrappedComponent {...props} />;
  };
};
