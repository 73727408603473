import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";

import { makeStyles } from "@material-ui/core/styles";

import {
  getMinutesFromSeconds,
  formatTimestamp,
  formatMinutes,
  formatSeconds
} from "../util";

const useStyles = makeStyles(theme => ({
  overflowOn: {
    "& svg": {
      overflow: "visible"
    }
  }
}));

export const TimeChart = ({ apiResults, threshold, displayHere }) => {
  const classes = useStyles();
  console.log("TimeChart apiResults: ", apiResults);
  const durationInNoTrafficMinutes = getMinutesFromSeconds(
    apiResults[0].duration
  );
  const apiResultToDataPoint = ({
    resultsObtained,
    duration,
    durationInTraffic,
    hereSummary
  }) => ({
    resultsObtained: formatTimestamp(resultsObtained, true),
    durationMinutes: getMinutesFromSeconds(duration),
    durationInTrafficFormatted: formatSeconds(durationInTraffic),
    durationInTrafficMinutes: getMinutesFromSeconds(durationInTraffic),
    hereTrafficTimeMintes:
      hereSummary && hereSummary.trafficTime
        ? getMinutesFromSeconds(hereSummary.trafficTime)
        : null
  });

  const data = apiResults.map(apiResultToDataPoint).reverse();

  const getYAxisDomain = () => {
    return [
      Math.floor(durationInNoTrafficMinutes * 0.9),
      dataMax => Math.ceil(dataMax * 1.05)
    ];
  };

  return (
    <ResponsiveContainer width="99%" height={250}>
      <LineChart
        data={data}
        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
        className={classes.overflowOn}
      >
        <Line
          type="monotone"
          dataKey="durationInTrafficMinutes"
          stroke="#8884d8"
        />
        {displayHere ? (
          <Line
            type="monotone"
            dataKey="hereTrafficTimeMintes"
            stroke="#82ca9d"
          />
        ) : null}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="resultsObtained" />
        {threshold !== null ? (
          <ReferenceLine
            y={threshold}
            label={`Threshold - ${formatMinutes(threshold)}`}
            stroke="blue"
            strokeWidth="1"
            strokeDasharray="3 3"
          />
        ) : (
          <ReferenceLine
            y={durationInNoTrafficMinutes}
            label={`With no traffic - ${formatMinutes(
              durationInNoTrafficMinutes
            )}`}
            strokeWidth="1"
            stroke="blue"
            strokeDasharray="3 3"
          />
        )}

        <Tooltip formatter={(value, name, props) => [formatMinutes(value)]} />
        <YAxis
          domain={getYAxisDomain()}
          tickFormatter={tick => formatMinutes(tick, true)}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
