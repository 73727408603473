import React from "react";

import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

//import featureImage from "../../assets/hero.png";
import thumbnailTrack from "../../assets/desktop-screenshot.png";
import thumbnailCombinedMockup from "../../assets/combined-mockup.png";
import thumbnailPhoneFavorites from "../../assets/phone-favorites.png";
import thumbnailFacebook from "../../assets/facebook-login.png";
import logo from "../../assets/logo-transparent.svg";

import Button from "./Button";
import { Feature } from "./Feature";
import { CallToAction } from "./CallToAction";
import Footer from "./Footer";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    textAlign: "center"
  },
  pageHeader: {
    backgroundColor: "#4960a61f"
  },
  tagline: {
    paddingTop: "130px",
    fontWeight: "bold"
  },
  subtitle: {
    color: "#8f8f8f"
  },
  hero: {
    marginTop: "61px",
    width: "100%"
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  logo: {
    maxWidth: "180px"
  },
  featuresContainer: {
    maxWidth: "1000px"
  },
  footnotes: {
    padding: "40px"
  }
}));

export default function LandingPage() {
  const classes = useStyles();
  const history = useHistory();

  const features = [
    {
      name: "Track travel time on specific routes",
      description:
        "Save time and energy by letting Roadgauge monitor traffic conditions for you and let you know when's a good time to leave",
      imgUrl: thumbnailTrack
    },
    {
      name: "Use on your PC or mobile",
      description:
        "Roadgauge is a Progressive Web App and works just as well on PCs and mobile devices.",
      imgUrl: thumbnailCombinedMockup
    },
    {
      name: "Add Favorites",
      description:
        "Add your favorite places and routes to view current travel time, start tracking & navigate easily from one screen.",
      imgUrl: thumbnailPhoneFavorites
    },
    {
      name: "Sync data across multiple devices",
      description:
        "Optionally login with Facebook (other integrations coming soon) to access your data across multiple devices.",
      imgUrl: thumbnailFacebook
    }
  ];

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <div className={classes.title}>
            <img src={logo} className={classes.logo} />
          </div>
          <Button />
        </Toolbar>
      </AppBar>
      <div className={classes.pageHeader}>
        <Container maxWidth="lg" className={classes.container}>
          <Typography variant="h3" gutterBottom className={classes.tagline}>
            Avoid Road Rage
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.subtitle}>
            Get notified when traffic dies down so you can avoid traffic & save
            time.
          </Typography>
          <img
            alt={"Dashboard"}
            src={thumbnailCombinedMockup}
            className={classes.hero}
          />
        </Container>
      </div>
      <Container maxWidth="lg" className={classes.featuresContainer}>
        {features.map((f, index) => (
          <Feature key={index} {...f} reverse={index % 2 !== 0} />
        ))}
      </Container>

      <CallToAction history={history} />
      <Container maxWidth="lg" className={classes.footnotes}>
        <Typography variant="caption">
          1. Push notifications work on PC, Mac & Android, but not yet on iOS.
          We have a native app in development that will enable notifications on
          iOS as well.
        </Typography>
      </Container>
      <Footer />
    </div>
  );
}
