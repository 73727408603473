const hasValidRouteInfo = result =>
  result !== null &&
  result.status === "OK" &&
  result.routes.length > 0 &&
  result.routes[0].legs.length > 0 &&
  result.routes[0].legs[0].duration !== undefined &&
  result.routes[0].legs[0].duration_in_traffic !== undefined;

const hasValidMatrixResult = (result, status, numDestinations) =>
  result !== null &&
  status === "OK" &&
  result.rows.length > 0 &&
  result.rows[0].elements.length === numDestinations &&
  result.rows[0].elements[0].duration !== undefined &&
  result.rows[0].elements[0].duration_in_traffic !== undefined;

export const getDirections = (origin, destination, avoidTolls = false) => {
  const directionsService = new window["google"].maps.DirectionsService();
  let request = {
    origin: origin.formatted_address,
    destination: destination.formatted_address,
    travelMode: "DRIVING",
    drivingOptions: {
      departureTime: new Date()
    },
    avoidTolls
  };
  return new Promise((resolve, reject) => {
    directionsService.route(request, (result, status) => {
      if (hasValidRouteInfo(result)) {
        console.log("got good result: ", result);
        resolve(result);
      } else {
        reject(result);
      }
    });
  });
};

export const getDirectionMatrix = (
  origin,
  destinations,
  avoidTolls = false
) => {
  const distanceMatrixService = new window[
    "google"
  ].maps.DistanceMatrixService();
  const request = {
    origins: [origin.formatted_address],
    destinations: destinations.map(location => location.formatted_address),
    travelMode: "DRIVING",
    drivingOptions: {
      departureTime: new Date()
    },
    avoidTolls
  };
  return new Promise((resolve, reject) => {
    distanceMatrixService.getDistanceMatrix(request, (result, status) => {
      if (hasValidMatrixResult(result, status, destinations.length)) {
        console.log("got good result: ", result);
        resolve(result);
      } else {
        reject(result);
      }
    });
  });
};
