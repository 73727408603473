import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
export default function AlertDialog({ open }) {
  const [dismissed, setDismissed] = useState(false);

  const handleClose = () => setDismissed(true);
  return (
    <Dialog
      open={open && !dismissed}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Location blocked"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You must unblock location tracking for this site to use your current
          location.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
