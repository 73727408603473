import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "15px",
    maxWidth: "500px"
  }
}));

export const NewUserEducation = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography variant="h2">Welcome</Typography>
      <br />
      <Typography variant="body1">
        <b>Road Gauge</b> was built for those times when you need to get
        somewhere but you'd like to optimize for the least amount of time spent
        in traffic as opposed to needing to leave right away.
      </Typography>
      <br />
      <Typography variant="body1">
        Once you add a route we will track it for the desired time and
        automatically update the status page with the latest traffic info. If
        you are on a modern Desktop browser or Android we will even send you a
        notification when it's a good time to leave.
      </Typography>
      <br />
      <Button variant="contained" component={Link} to="/add-route">
        Add a Route
      </Button>
    </Paper>
  );
};
