import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AddIcon from "@material-ui/icons/Add";
import FavoriteIcon from "@material-ui/icons/Favorite";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import SettingsIcon from "@material-ui/icons/Settings";

import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%"
  }
});

export const BottomNavComponent = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const location = useLocation();

  const paths = ["/status", "/add-route", "/favorites", "/settings"];

  useEffect(() => {
    if (location !== null) {
      setValue(paths.findIndex(value => value === location.pathname));
    }
  }, [location, paths]);
  if (location.pathname === "/landing-page") {
    return null;
  }
  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        label="Status"
        icon={<WatchLaterIcon />}
        component={Link}
        to={paths[0]}
      />
      <BottomNavigationAction
        label="Add"
        icon={<AddIcon />}
        component={Link}
        to={paths[1]}
      />
      <BottomNavigationAction
        label="Favorites"
        icon={<FavoriteIcon />}
        component={Link}
        to={paths[2]}
      />
      <BottomNavigationAction
        label="Settings"
        icon={<SettingsIcon />}
        component={Link}
        to={paths[3]}
      />
    </BottomNavigation>
  );
};
