// this is adapted from 'react-google-autocomplete' npm package
// I changed it to a function component that makes use of hooks
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Textfield from "@material-ui/core/Textfield";
const google = window.google;

export default ({ disabled, ...props }) => {
  const propTypes = {
    onPlaceSelected: PropTypes.func,
    types: PropTypes.array,
    componentRestrictions: PropTypes.object,
    bounds: PropTypes.object,
    fields: PropTypes.array
  };

  const inputRef = useRef(null);

  let autocomplete = null;
  let event = null;

  useEffect(() => {
    const {
      types = ["(cities)"],
      componentRestrictions,
      bounds,
      fields = [
        "address_components",
        "geometry.location",
        "place_id",
        "formatted_address"
      ]
    } = props;
    const config = {
      types,
      bounds,
      fields
    };

    if (componentRestrictions) {
      config.componentRestrictions = componentRestrictions;
    }

    autocomplete = new google.maps.places.Autocomplete(
      inputRef.current,
      config
    );

    event = autocomplete.addListener("place_changed", onSelected);
    return () => event && event.remove();
  }, [disabled]);

  const onSelected = () => {
    if (props.onPlaceSelected && autocomplete) {
      props.onPlaceSelected(autocomplete.getPlace(), inputRef.current);
    }
  };
  const {
    onPlaceSelected,
    types,
    componentRestrictions,
    bounds,
    ...rest
  } = props;
  return (
    <Textfield
      variant={disabled ? "filled" : "outlined"}
      disabled={disabled}
      inputRef={inputRef}
      {...rest}
    />
  );
};
