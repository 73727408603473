import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction
} from "@material-ui/core";

import firebase from "../../util/firebase";
import tracking from "../../services/tracking";

import {
  clickNavigate,
  getCurrentLocation,
  isMobileDevice,
  formatSeconds
} from "../../util";

import { FavoriteSpeedDial } from "./FavoriteSpeedDial";
import { FavoriteActions } from "./FavoriteActions";
import { getDirections } from "../../services/google";

import { ArrowTooltip } from "../../components/ArrowTooltip";

const useStyles = makeStyles(theme => ({
  avatar: {
    paddingRight: "8px",
    minWidth: "64px",
    display: "flex",
    justifyContent: "center"
  },
  good: {
    color: "green"
  },
  ok: {
    color: "orange"
  },
  bad: {
    color: "red"
  },
  root: {
    paddingRight: "150px"
  },
  rootMobile: {
    paddingLeft: "8px",
    paddingRight: "54px"
  },
  actionRoot: {
    right: "8px"
  }
}));

export const FavoriteListItem = withRouter(
  ({ favorite, history, currentLocation }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [routeInfo, setRouteInfo] = useState(null);

    const isRoute = !!favorite.origin;

    const origin = favorite.origin;
    const destination = favorite.origin ? favorite.destination : favorite;

    const classes = useStyles();

    const storeRouteInfo = result => {
      const legInfo = result.routes[0].legs[0];
      setRouteInfo({
        duration: legInfo.duration,
        duration_in_traffic: legInfo.duration_in_traffic,
        resultsObtained: new Date()
      });
    };

    useEffect(() => {
      const getRouteTimes = async () => {
        try {
          const result = await getDirections(
            origin ? origin : currentLocation,
            destination
          );
          storeRouteInfo(result);
        } catch (error) {
          if (error.message === "User denied Geolocation")
            console.log("error getting user location");
          else {
            console.log("error getting route times: ", error);
          }
        }
      };
      getRouteTimes();
    }, [favorite, currentLocation]);

    const getClass = status => {
      const percentDelta =
        (status.duration_in_traffic.value - status.duration.value) /
        status.duration.value;
      if (percentDelta < 0.1) return "good";
      else if (percentDelta < 0.2) return "ok";
      else return "bad";
    };

    const handleDelete = () => {
      firebase.removeFavorite(favorite);
      tracking.logEvent("remove_favorite", favorite);
    };
    const handleNavigate = () => clickNavigate(destination.formatted_address);

    const handleTrackFavorite = async () => {
      const isCurrentTask = await firebase.isCurrentTask();
      if (isCurrentTask) {
        setDialogOpen(true);
      } else {
        submitNotifyTask();
      }
    };

    const submitNotifyTask = async () => {
      const task = await firebase.submitNotifyTask(
        isRoute ? origin : await getCurrentLocation(),
        destination,
        null,
        routeInfo,
        1
      );
      tracking.logEvent("submit_notify_task_favorite", task);
      history.push("/status");
    };

    const handleDialogAccept = () => {
      submitNotifyTask();
    };
    const handleDialogClose = () => {
      setDialogOpen(false);
    };
    const nameInAddress =
      destination.formatted_address.indexOf(destination.name) !== -1;
    const ListItemTextPrimary = (
      <ArrowTooltip
        title={destination.formatted_address}
        placement="top"
        enterTouchDelay={400}
        disableFocusListener={nameInAddress}
        disableHoverListener={nameInAddress}
        disableTouchListener={nameInAddress}
      >
        <span>
          {nameInAddress ? destination.formatted_address : destination.name}
        </span>
      </ArrowTooltip>
    );

    return (
      <div>
        <ListItem
          className={isMobileDevice() ? classes.rootMobile : classes.root}
        >
          <ListItemAvatar
            className={clsx(
              classes.avatar,
              routeInfo ? classes[getClass(routeInfo)] : null
            )}
          >
            <span>
              {routeInfo
                ? formatSeconds(routeInfo.duration_in_traffic.value, true)
                : "..."}
            </span>
          </ListItemAvatar>
          <ListItemText
            secondary={`from ${origin ? origin.name : "your location"}`}
          >
            {ListItemTextPrimary}
          </ListItemText>
          <ListItemSecondaryAction classes={{ root: classes.actionRoot }}>
            {isMobileDevice() ? (
              <FavoriteSpeedDial
                handleDelete={handleDelete}
                handleTrackFavorite={handleTrackFavorite}
                handleNavigate={handleNavigate}
              />
            ) : (
              <FavoriteActions
                handleDelete={handleDelete}
                handleTrackFavorite={handleTrackFavorite}
                handleNavigate={handleNavigate}
              />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Replace currently tracked route?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You can only track one route at a time. Would you like to replace
              the one you are already tracking?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDialogAccept} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);
