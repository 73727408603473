import firebase from "../services/firebase";

import { CURRENT_LOCATION_STRING } from "../strings";
import { getCurrentLocation } from ".";

class Firebase {
  constructor() {
    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
    this.analytics = firebase.analytics();
    this.setupFacebookRedirect();
    this.isInitialized = this.initializeUser();
    try {
      this.messaging = firebase.messaging();
      this.isInitialized.then(() => this.setUpMessaging());
    } catch (err) {
      this.messaging = null;
      console.log("messaging not supported on this browser: ", err);
    }
  }
  setUpMessaging() {
    this.messaging.usePublicVapidKey(
      "BIEXoRXXihJynOiCEvtkyLvRIIi5R9DjSaDUBkRU_9EPNban4wcPV--EKLNsMvL40fWx4ViAV5Ls1tnAhe7I6ns"
    );
    this.messaging
      .requestPermission()
      .then(() => {
        console.log("Have messaging permission");
        return this.messaging.getToken();
      })
      .then(async token => {
        const userDoc = this.userDoc();
        const userData = (await userDoc.get()).data();
        if (userData.notificationToken !== token) {
          return userDoc.update({
            notificationToken: token
          });
        }
      })
      .then(() => {
        console.log("notification token updated");
      })
      .catch(err => {
        console.log("messaging permission error occurred error ", err);
      });
  }
  async initializeUser() {
    return new Promise(resolve =>
      this.auth.onAuthStateChanged(user => {
        console.log("user: ", user);
        if (!user) {
          return resolve(false);
        } else resolve(true);
      })
    );
  }
  onAuthStateChanged(next, error) {
    return this.auth.onAuthStateChanged(next, error);
  }
  setupFacebookRedirect() {
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        if (result.credential) {
          // Accounts successfully linked.
          console.log("succesful account linking");
          var credential = result.credential;
          var user = result.user;
          // ...
        }
      })
      .catch(error => {
        console.log("falied account linking:", error);
        if (error.credential) {
          //failure due to previously linked acount (is this the only case?)
          this.handleAccountConflict(error.credential);
        }
        // Handle Errors here.
        // ...
      });
  }
  async handleAccountConflict(credential) {
    // grab favorites from currently signed in user;
    const favorites = await this.getFavorites();

    //save reference to previous user
    const prevUser = this.auth.currentUser;

    // Sign in using returned credentials
    this.auth
      .signInWithCredential(credential)
      .then(user => {
        console.log("Sign In Success", user);
        // Merge prevUser and currentUser data stored in Firebase.
        if (favorites && favorites.length > 0) {
          this.addFavorites(favorites);
        }
        //delete prev user's account - this will trigger deletion of their data through a firebase function
        prevUser.delete();
      })
      .catch(error => {
        //TODO: possible issue, if there is  a failure we can't roll back the user (data) deletion.
        //will they still be signed in to prevUser at this point or do i need to sign bak in?
        console.log("Sign In Error", error);
      });
  }
  linkWithFacebook() {
    const provider = new firebase.auth.FacebookAuthProvider();
    this.auth.currentUser.linkWithRedirect(provider);
  }
  isUserAnonymous() {
    return this.auth.currentUser.isAnonymous;
  }
  logout() {
    this.auth.signOut();
  }
  signIn() {
    return this.auth
      .signInAnonymously()
      .then(result => {
        console.log("anonymous sign in successful: ", result);
        return result.user.uid;
      })
      .catch(err => {
        console.log("error with anonymous sign in: ", err);
      });
  }

  isMessagingSupported() {
    return !!this.messaging;
  }

  getUserId() {
    return this.auth.currentUser && this.auth.currentUser.uid;
  }

  userDoc() {
    return (
      this.auth.currentUser &&
      this.firestore.collection("users").doc(this.getUserId())
    );
  }
  archivedTasks() {
    return (
      this.auth.currentUser &&
      this.firestore.collection(`users/${this.getUserId()}/archivedTasks`)
    );
  }

  //not including current task
  getLatestTasks(num) {
    return this.archivedTasks()
      .orderBy("created", "desc")
      .limit(num)
      .get();
  }

  setSettingsListener(listener) {
    return this.userDoc().onSnapshot(snapshot => {
      const data = snapshot.data();
      if (data && data.settings) {
        listener(data.settings);
      }
    });
  }

  updateSettings(settings) {
    return this.userDoc().set({ settings }, { merge: true });
  }

  getSettings() {
    return this.userDoc()
      .get()
      .then(doc => doc.data().settings);
  }

  async refreshLatestRoute() {
    const latestTasks = await this.getLatestTasks(1);
    const task = latestTasks.docs[0].data();
    const expiryDiffSeconds = task.expiry.seconds - task.created.seconds;
    task.apiResults = [];
    task.completed = false;
    task.created = new Date();
    task.expiry = new Date(Date.now() + expiryDiffSeconds * 1000);
    task.expiryTime = task.expiry.getTime();
    if (task.origin.name === CURRENT_LOCATION_STRING) {
      task.origin = await getCurrentLocation();
    }
    //remove
    task.thresholdInMinutes = null;
    this.firestore
      .collection("tasksToRefresh")
      .doc(this.getUserId())
      .set({ currentTask: task });
  }

  logEvent(eventName, eventParams, options) {
    this.analytics.logEvent(eventName, eventParams, options);
  }

  getCurrentTask() {
    return this.userDoc()
      .get()
      .then(doc => doc.data().currentTask);
  }

  // TODO: is there a better way to update a field of a subobject of a document?
  updateCurrentTask(updates) {
    return this.getCurrentTask().then(currentTask =>
      this.userDoc().update({
        currentTask: {
          ...currentTask,
          ...updates
        }
      })
    );
  }

  async addHour() {
    try {
      const oneHour = 1000 * 60 * 60;
      const currentTask = await this.getCurrentTask();
      const expiryTime = currentTask.expiryTime + oneHour;
      const expiry = new Date(expiryTime);
      await this.userDoc().update({
        currentTask: {
          ...currentTask,
          expiry,
          expiryTime
        }
      });
    } catch (err) {
      console.log("error adding one Hour: ", err);
    }
  }
  async updateThreshhold(thresholdInMinutes) {
    try {
      await this.updateCurrentTask({ thresholdInMinutes });
    } catch (err) {
      console.log("error updating threshold: ", err);
    }
  }

  isCurrentTask = () => {
    return this.userDoc()
      .get()
      .then(doc => doc.data())
      .then(data => data && !!data.currentTask);
  };

  submitNotifyTask = (
    origin,
    destination,
    threshold,
    routeInfo,
    expiryHours
  ) => {
    console.log("submitNotifyTask");
    let expiry = new Date(Date.now() + 1000 * 60 * 60 * expiryHours);
    const currentTask = {
      origin,
      destination,
      created: new Date(),
      expiry,
      expiryTime: expiry.getTime(),
      thresholdInMinutes: threshold,
      completed: false,
      apiResults: [
        {
          duration: routeInfo.duration.value,
          durationInTraffic: routeInfo.duration_in_traffic.value,
          resultsObtained: routeInfo.resultsObtained
        }
      ]
    };
    return this.userDoc()
      .set(
        {
          currentTask
        },
        { merge: true }
      )
      .then(() => currentTask);
  };

  getFavorites() {
    return this.userDoc()
      .get()
      .then(doc => doc.data().favorites);
  }
  addFavorites(favorites) {
    return this.userDoc().update({
      favorites: firebase.firestore.FieldValue.arrayUnion(...favorites)
    });
  }

  addFavorite(favorite) {
    return this.userDoc().set(
      {
        favorites: firebase.firestore.FieldValue.arrayUnion(
          favorite.origin ? favorite : favorite.destination
        )
      },
      {
        merge: true
      }
    );
  }
  removeFavorite(favorite) {
    return this.userDoc().update({
      favorites: firebase.firestore.FieldValue.arrayRemove(favorite)
    });
  }
}

export default new Firebase();
